import React from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { FaFacebookF, FaInstagram } from "react-icons/fa"; // Import social icons
import "./contact.css";
import { ContactProps } from "../../../hooks/useFetchContactinformation";

interface ContactsProps {
  data: ContactProps | null;
}

const Contacts: React.FC<ContactsProps> = ({ data }) => {
  if (!data) {
    return <div>Ingen data</div>;
  }

  const { email, adress, phonenumber } = data;

  return (
    <section id="contact" className="contact">
      <h1 className="medium-margin-bottom large-margin-top">
        Kontakt Skeive Stilker
      </h1>
      <div className="contact-info__banners large-margin-top large-margin-bottom">
        <ContactBanner
          icon={<AiOutlineMail size={35} className="icon" />}
          link={`mailto:${email}`}
          text={email}
        />
        <ContactBanner
          icon={<AiOutlinePhone size={35} className="icon" />}
          link={`tel:${phonenumber}`}
          text={phonenumber}
        />
        <ContactBanner
          icon={<BiMap size={35} className="icon" />}
          text={adress}
        />
        <div className="banner__container">
          <div className="banner">
            <h4>Følg Skeive Stilker</h4>
            <div className="footer__icons">
              <SocialIcon
                link="https://www.facebook.com/skeivestilker"
                icon={<FaFacebookF size={35} />}
              />
              <SocialIcon
                link="https://www.instagram.com/skeivestilker/"
                icon={<FaInstagram size={35} />}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ContactBanner: React.FC<{
  icon: React.ReactNode;
  link?: string;
  text: string;
}> = ({ icon, link, text }) => (
  <div className="banner__container">
    <div className="banner">
      {icon}
      {link ? (
        <a className="phone__link" href={link}>
          {text}
        </a>
      ) : (
        <p>{text}</p>
      )}
    </div>
  </div>
);

const SocialIcon: React.FC<{ link: string; icon: React.ReactNode }> = ({
  link,
  icon,
}) => (
  <a href={link} target="_blank" rel="noreferrer" className="footer__icon">
    {icon}
  </a>
);

export default Contacts;
