import MyCustomNavbar from "../../components/navbar/Navbar";
import "./home.css";
import Contacts from "./contact/Contacts";
import Footer from "../../components/footer/Footer";
import useCarouselleData from "../../hooks/useCarouselledata";
import FlowerAnimation from "../../animations/FlowerAnimation";
import useContactInfo from "../../hooks/useFetchContactinformation";
import HeroSection from "./hero/HeroSection";

const Homepage = () => {
  const { carouselleSlides, isCarouselleLoading } = useCarouselleData();
  const { data: contactinfo, loading: contactinfoLoading } = useContactInfo();

  const showLoadingAnimation = isCarouselleLoading || contactinfoLoading;

  return (
    <div className="homepage">
      {showLoadingAnimation ? (
        <div className="animation__container">
          <FlowerAnimation />
        </div>
      ) : (
        <>
          <MyCustomNavbar />
          <HeroSection carouselleSlides={carouselleSlides} />
          <Contacts data={contactinfo} />
          <Footer data={contactinfo} />
        </>
      )}
    </div>
  );
};

export default Homepage;
