import React from "react";
import { CarouselleProps } from "../../../hooks/useCarouselledata";
import NewsletterSubscribe from "../../../components/newsletter-form/NewsletterSubscribe";
import "./hero.css";

interface IHeroSection {
  carouselleSlides: CarouselleProps[];
}

const HeroSection: React.FC<IHeroSection> = ({ carouselleSlides }) => {
  const {
    image = "./flowers.jpg",
    title = "SKEIVE STILKER",
    description = "Snittblomster rett fra åkeren til deg",
  } = carouselleSlides[0] || {};

  return (
    <section
      id="hero"
      className="hero"
      style={
        image
          ? { backgroundImage: `url(${image})` }
          : { backgroundImage: "./flowers.jpg" }
      }
    >
      <div className="hero__overlay">
        <div className="hero__content">
          <div className="hero__text">
            <h1 className="hero__title">{title}</h1>
            <p className="hero__description">{description}</p>
          </div>
          <NewsletterSubscribe />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
